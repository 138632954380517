.autocomplete-suggestions { padding: 5px; font-size: 13px; border: 1px solid #edeff1; background: #edeff1; overflow: auto; }
.autocomplete-suggestion { padding: 3px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F9F9F9; color: #000;}
.autocomplete-suggestions strong { font-weight: normal; color: #e02222; }
.autocomplete-group { padding: 3px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

.autocomplete-suggestions::-webkit-scrollbar {
	width: 6px;
  	background-color: #edeff1;	
}
.autocomplete-suggestions::-webkit-scrollbar-track {
  	background-color: #edeff1;
}
.autocomplete-suggestions::-webkit-scrollbar-thumb {
  	background-color: #e84d1c;
}
