/* Loading dual-ring */
div#loading {
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.15);
	z-index: 19999;
	display: none;
	/*cursor: wait;*/
}

div#loading .lds-dual-ring {
	display: block;
	position: absolute;
	left: calc(50% - 32px);
	top: calc(50% - 32px);
}

div#loading .lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #444;
	border-color: #444 transparent #444 transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Loading pulse */
@keyframes pulse-1 {
	0% {
		top: 36px;
		height: 128px
	}

	50% {
		top: 60px;
		height: 80px
	}

	100% {
		top: 60px;
		height: 80px
	}
}

@keyframes pulse-2 {
	0% {
		top: 41.99999999999999px;
		height: 116.00000000000001px
	}

	50% {
		top: 60px;
		height: 80px
	}

	100% {
		top: 60px;
		height: 80px
	}
}

@keyframes pulse-3 {
	0% {
		top: 48px;
		height: 104px
	}

	50% {
		top: 60px;
		height: 80px
	}

	100% {
		top: 60px;
		height: 80px
	}
}

div#loading .pulse div {
	position: absolute;
	width: 30px
}

div#loading .pulse div:nth-child(1) {
	left: 35px;
	background: #cb1c1c;
	animation: pulse-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation-delay: -0.2s
}

div#loading .pulse div:nth-child(2) {
	left: 85px;
	background: #f57c00;
	animation: pulse-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation-delay: -0.1s
}

div#loading .pulse div:nth-child(3) {
	left: 135px;
	background: #008037;
	animation: pulse-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation-delay: undefineds
}

div#loading .pulse {
	display: block;
	position: absolute;
	left: calc(50% - 95px);
	top: calc(50% - 95px);
}

div#loading .pulse div {
	box-sizing: content-box;
}


fieldset {
	border: 0 none;
}


.galerie, .galerie1 {
	margin-bottom: 10px;
}

.galerie {
	/*float:left; */
	display: inline-block;
}

.galerie1 {
	float: right;
}

.galerie a, .galerie1 a {
	text-decoration: none;
	border: 0;
}

.galerie .image, .galerie1 .image {
	vertical-align: top;
	margin-bottom: 20px;
}

.galerie .image {
	/*float:left; */
	display: inline-block;
}

.galerie .image {
	margin-right: 10px;
}

.galerie .image+.image+.image+.image {
	margin-right: 0px;
}

.galerie1 .image {
	margin-left: 10px;
}

.galerie .title, .galerie1 .title {
	position: relative;
	display: block;
	text-align: center;
	margin-top: 0px;
	/* height:1em; */
	overflow: hidden;
}

tr.nobr td, td.nobr, span.nobr, a.nobr {
	white-space: nowrap;
}

tr.top td, td.top, span.top, a.top {
	vertical-align: top;
}

.clear {
	clear: both;
}

.body {}

#bannerTop, #bannerBottom {
	position: relative;
	display: block;
	margin: 0px auto;
	text-align: center;
}


.Form input[type='text'], input[type='email'], textarea, select {}

/* form success/error info*/
.success_wrapper .success {
	left: 0;
	top: 0px;
	border: 1px solid #272626;
	display: none;
	position: relative;
	width: 100%;
	background: #272626;
	color: #fff;
	text-align: center;
	z-index: 9999999999;
	padding: 15px;
	margin: 0px auto;
}

.success_wrapper {
	left: 0;
	top: 0px;
	position: fixed;
	margin: 0px auto;
	width: 100%;
	z-index: 9999999999;
}

.error_wrapper .error {
	left: 0;
	top: 0px;
	border: 1px solid #e84d1c;
	background: #e84d1c;
	color: #fff;
	display: none;
	position: relative;
	width: 100%;
	text-align: center;
	z-index: 9999999999;
	padding: 15px;
	margin: 0px auto;
}

.error_wrapper {
	left: 0;
	top: 0px;
	position: fixed;
	margin: 0px auto;
	width: 100%;
	z-index: 9999999999;
}

.inputErr {
	/*margin-left:5px;*/
	text-transform: uppercase;
	font-family: sans-serif;
	font-size: 12px;
	color: #e6400c;
	font-style: italic;
}

/* form success/error info*/

.right {
	float: right;
}

.tright {
	text-align: right;
}

.tleft {
	text-align: left;
}

.red {
	color: #ff0000;
}

.middle {
	vertical-align: middle;
}

.wide {
	width: 100%;
}

.white, .white a {
	color: #fff;
}

.center {
	text-align: center;
	margin: 0px auto;
}

.shipimage, .payimage {
	margin: 10px;
}

.filtr {
	float: left;
	margin: 0;
	padding: 0;
}

.filtr span {
	padding: 0 0.8em 0.8em 0.8em;
	float: left;
	display: inline-block;
}

.filtr span h4 {
	font-weight: bold;
	margin: 0;
	padding: 0;
	line-height: 27px;
}

.filtr span>span {
	display: inline-block;
	margin: 0;
	padding: 0;
	padding-right: 10px;
	clear: both;
	height: auto;
}

/* Email Autocomplete */
.eac-sugg {
	color: #ccc !important;
}

/* Email Autocomplete */

/* SEARCH AUTOCOMPLETE ! (jquery.autocomplete.min.js) */
.autocomplete-suggestions {
	border: 1px solid #999;
	background: #fff;
	cursor: default;
	overflow: auto;
	/*
	-webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); 
	-moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); 
	box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); 
	*/
}

.autocomplete-suggestion {
	padding: 2px 5px;
	white-space: nowrap;
	overflow: hidden;
}

.autocomplete-no-suggestion {
	padding: 2px 5px;
}

.autocomplete-selected {
	background: #F0F0F0;
}

.autocomplete-suggestions strong {
	font-weight: bold;
	color: #000;
}

.autocomplete-group {
	padding: 2px 5px;
}

.autocomplete-group strong {
	font-weight: bold;
	font-size: 1em;
	color: #000;
	display: block;
	border-bottom: 1px solid #000;
}

/* SEARCH AUTOCOMPLETE ! (jquery.autocomplete.min.js) */